import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SystemEventService} from '../../../../system-event/services/system-event.service';
import {UrbannetService} from 'frontend/src/integrations/urbannet/services/urbannet.service';
import {SystemEvent} from '../../../../system-event/classes/system-event';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LoadingMaskOptions} from '../../../../../classes/loading-mask-options';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'eaglei-urban-net-upload',
    templateUrl: './urban-net-upload.component.html',
    styleUrls: ['./urban-net-upload.component.scss'],
})
export class UrbanNetUploadComponent {
    public systemEventService = inject(SystemEventService);
    private urbanNetService = inject(UrbannetService);
    private popup = inject(MatSnackBar);
    private dialogRef = inject(MatDialogRef);

    public loadingMaskOptions = new LoadingMaskOptions();
    public showFileSizeError: boolean;
    public showFileTypeError: boolean;
    public loading = new Subject<boolean>();
    public event: SystemEvent;
    public file: File;

    public formGroup = new FormGroup<any>({
        file: new FormControl<File>(null, {validators: Validators.required}),
        event: new FormControl<SystemEvent>(null, {validators: Validators.required}),
    });

    constructor() {}

    public addFile(event: FileList): void {
        const file = event.item(0);
        this.formGroup.get('file').setValue(file);
    }

    public onUpload() {
        const body = this.formGroup.getRawValue();
        this.loading.next(true);
        this.urbanNetService
            .uploadFile(body.event, body.file)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.loading.next(false);
                    this.popup.open('Successfully Added URBAN-NET file', 'X', {panelClass: 'dialog-success', duration: 5_000});
                },
                error: (error: any) => {
                    console.error(error);
                    this.popup.open('Failed Adding URBAN-NET file', 'X', {panelClass: 'dialog-failure', duration: 5_000});
                },
            });
        this.dialogRef.close();
    }
}
