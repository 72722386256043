<div class="container">
    <div class="dialog-header">
        <h4>URBAN-NET Upload</h4>
        <button mat-icon-button matDialogClose>
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>

    <div class="container">
        <div *ngIf="formGroup.get('file').value; else dropArea">
            <ng-container>
                <div class="selected-file" [class.mat-elevation-z2]="isActive">
                    <div>
                        <fa-icon [icon]="['far', 'file']"></fa-icon>
                        <span>{{ this.formGroup.get('file').value.name }}</span>
                    </div>

                    <button mat-icon-button (click)="formGroup.get('file').reset()">
                        <fa-icon icon="trash"></fa-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="auto-mask" *ngIf="loadingMaskOptions.showMask">
            <span class="mask-message">
                <span>{{ loadingMaskOptions.text }}</span>
                <mat-progress-bar *ngIf="loadingMaskOptions.showSpinner" mode="indeterminate"></mat-progress-bar>
            </span>
        </div>

        <ng-template #dropArea>
            <div class="drop-area" eagleiFileDragAndDrop [multiple]="false" (filesDropped)="addFile($event)">
                <span class="drop-text">
                    <span> Drag and drop <i>file</i> or <a (click)="fileUpload.click()">browse</a> </span>
                    <p class="file-size-text">Max file size: 250MB</p>
                </span>
            </div>
        </ng-template>

        <div *ngIf="showFileSizeError" class="file-error-text">
            <p>Uploaded file exceeds max size of 250MB.</p>
        </div>

        <div *ngIf="showFileTypeError" class="file-error-text">
            <p>Only files with .json extension are allowed.</p>
        </div>

        <input #fileUpload type="file" accept=".json" (change)="addFile(fileUpload.files)" hidden />
    </div>
    <div class="container">
        <form [formGroup]="formGroup" class="event-select">
            <mat-form-field appearance="outline" class="filter-select wide">
                <mat-select placeholder="Event" formControlName="event">
                    <mat-optgroup *ngFor="let item of systemEventService.groupedEvents$ | async | keyvalue" [label]="item.key | titlecase">
                        <mat-option *ngFor="let event of item.value" [value]="event">{{ event.name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div class="dialog-actions">
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onUpload()">Upload</button>
    </div>
</div>
